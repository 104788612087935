import { Lightning } from '@lightningjs/sdk';
import Networking from '@dw-cds/smart-tv-networking';

export default class NetworkingExample extends Lightning.Component {
    _init() {
        // Getting unauth token
        try {
            Networking.getUnAuthToken(
                response => {
                    console.info('Unauth token was got successfully', response);
                },
                error => {
                    console.error('Unauth token request returned error', error);
                },
            );
        } catch (error) {
            console.error('Unauth token returned error', error);
        }
    }
}
